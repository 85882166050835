@use '@lessonnine/design-tokens.lib/dist/scss/variables' as *;
@use 'src/viewLayer/styling/reset' as *;
@import '@lessonnine/design-tokens.lib/dist/css/fonts-semantic.css';

html {
  background: $color-theme-light-surface-layer-background;
  height: 100%;
  position: static;
  width: 100%;

  @media (min-width: $viewports-breakpoints-small) {
    background: $color-theme-light-surface-container-background;
  }
}

body {
  height: 100%;
  line-height: 1.5rem;
  min-height: 100vh;
  width: 100vw;

  & > [data-main] {
    height: 100%;

    @media (prefers-color-scheme: dark) {
      background-color: $color-theme-dark-surface-container-background;
    }
  }
}
